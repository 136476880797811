import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { usePermissionChecker } from '../../lib/hooks';
import { Permission, Team } from '../../lib/types';
import { PillLink, PillNav } from './Pill';
import { hasPolicy, useAccount } from '../../lib/account';

const TeamNav = ({ team, activePill }: { team: Team; activePill?: string }) => {
  const { t } = useTranslation();
  const permChecker = usePermissionChecker();
  const account = useAccount();
  return (
    <PillNav>
      {[
        {
          id: 'players',
          name: t('players'),
          href: `/team/${team.id}`,
          exact: true,
          can: permChecker.hasTeamPermission(team.id, Permission.ReadPlayer),
        },
        {
          id: 'leaderboard',
          name: t('leaderboard'),
          href: `/team/${team.id}/leaderboard`,
          can: permChecker.hasTeamPermission(team.id, Permission.ReadLeaderboard),
        },
        {
          id: 'market',
          name: t('market'),
          href: `/team/${team.id}/market`,
          can: hasPolicy(account, 'use_store') && permChecker.hasTeamPermission(team.id, Permission.ReadTeamStore),
        },
        {
          id: 'transactions',
          name: t('transactions'),
          href: `/team/${team.id}/transactions`,
          can: permChecker.hasTeamPermission(team.id, Permission.ReadTransaction),
        },
        {
          id: 'settings',
          name: t('settings'),
          href: `/team/${team.id}/settings`,
          can: permChecker.hasTeamPermission(team.id, Permission.UpdateTeam),
        },
      ]
        .filter((nav) => _.isUndefined(nav.can) || nav.can)
        .map((tab) => {
          const active = !_.isUndefined(activePill) ? activePill === tab.id : undefined;
          return (
            <PillLink key={tab.href} to={tab.href} exact={tab.exact} active={active}>
              {tab.name}
            </PillLink>
          );
        })}
    </PillNav>
  );
};

export default TeamNav;
