import fromUnixTime from 'date-fns/fromUnixTime';
import getUnixTime from 'date-fns/getUnixTime';
import { Field, Form, Formik, useFormik } from 'formik';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import { AccountContext, hasPolicy, isTrialing, useAccount } from '../../lib/account';
import { AuthContext, useUser } from '../../lib/auth';
import { usePermissionChecker } from '../../lib/hooks';
import { getErrorMessage } from '../../lib/i18n';
import { isCoreDomain, isDomainAdmin, isDomainAdminOf, isSuperUser } from '../../lib/perms';
import { useRepo } from '../../lib/repository';
import { Account, AccountPaymentType, AccountPolicy, AccountPolicyOverrides, Permission } from '../../lib/types';
import { classNames } from '../../lib/utils';
import { Button, DangerButton, LinkPrimaryButton, PrimaryButton } from '../Buttons';
import Checkbox from '../inputs/Checkbox';
import Input from '../inputs/Input';
import AccountSettingsPageLayout from '../layouts/AccountSettingsPage';
import { ActionModal, ActionModalButtons, ConfirmModal } from '../Modals';
import { NotificationError } from '../Notifications';
import { HasAccountPermission, HasPermission, IsSuperUser } from '../Permissions';
import { HasPolicy } from '../Policy';
import { broadcastSuccessToast } from '../Toasts';
import { DatePicker } from '../widgets/DateRangePicker';
import DropdownSelect, { DropdownSelectOption } from '../widgets/DropdownSelect';
import LangDropdown from '../widgets/LangDropdown';
import { SuperUserTag } from '../widgets/Tag';
import { usePlatforms } from '../../lib/queries';
import EnabledDropdown from '../widgets/EnabledDropdown';
import HelpText from '../forms/HelpText';

const AccountSettingsPage: React.FC<{}> = () => {
  const { t } = useTranslation();
  const { patchAccount } = useContext(AccountContext);
  const account = useAccount();
  const repo = useRepo();

  const accountMutation = useMutation((data: Partial<Account>) => repo.updateAccount(account.id, data));
  const formik = useFormik({
    initialValues: {
      name: account.name,
      locales: account.locales,
      domain_id: account.domain_id,
      platform: account.platform,
      tickets_auto_earn: account.tickets_auto_earn,
      tickets_enabled: account.tickets_enabled,
    },
    validationSchema: yup
      .object({
        name: yup.string().required(),
        locales: yup.array().of(yup.string()),
        domain_id: yup.string(),
        platform: yup.string(),
        tickets_auto_earn: yup.number().min(0).max(1000),
        tickets_enabled: yup.boolean(),
      })
      .required(),
    onSubmit: (values, form) => {
      accountMutation.mutate(values, {
        onSuccess: (data) => {
          patchAccount(data, true);
          broadcastSuccessToast(t('settingsSaved'));
        },
        onError: () => {
          form.resetForm();
          form.setSubmitting(false);
        },
      });
    },
  });

  return (
    <AccountSettingsPageLayout>
      <div className="flex-grow">
        <HasAccountPermission perm={Permission.UpdateAccount}>
          <form onSubmit={formik.handleSubmit} className="flex flex-col flex-grow">
            <div className="flex-grow">
              <div className="space-y-8">
                <HasPermission checker={isSuperUser}>
                  <div className="">
                    <div className="w-96">
                      <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">
                        ID
                        <SuperUserTag />
                      </div>
                      <div className="font-mono">{account.id}</div>
                    </div>
                  </div>
                </HasPermission>
                <div className="flex space-x-6">
                  <div className="w-96">
                    <label>
                      <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('name')}</div>
                      <div>
                        <Input value={formik.values.name} name="name" maxLength={255} type="text" onChange={formik.handleChange} />
                      </div>
                    </label>
                  </div>
                </div>
                <HasPolicy policy="use_l10n">
                  <div className="">
                    <div className="w-96">
                      <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('defaultLanguage')}</div>
                      <div>
                        <LangDropdown
                          selected={formik.values.locales ? formik.values.locales[0] : undefined}
                          onChange={(lang) => formik.setFieldValue('locales', [lang])}
                        />
                      </div>
                    </div>
                  </div>
                </HasPolicy>
                <HasPolicy policy="use_tickets">
                  <div className="">
                    <div className="w-96">
                      <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('tickets')}</div>
                      <div>
                        <EnabledDropdown
                          enabled={formik.values.tickets_enabled}
                          onChange={(enabled) => formik.setFieldValue('tickets_enabled', enabled)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="w-96">
                      <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('ticketsEarningPercentage')}</div>
                      <div>
                        <TicketsEarningPercentageDropdown
                          value={formik.values.tickets_auto_earn}
                          onChange={(n) => formik.setFieldValue('tickets_auto_earn', n)}
                          disabled={!formik.values.tickets_enabled}
                        />
                      </div>
                    </div>
                    <div className="max-w-2xl">
                      <HelpText>{t('ticketsEarningPercentageNote')}</HelpText>
                    </div>
                  </div>
                </HasPolicy>
                <HasPermission checker={isSuperUser}>
                  <div className="">
                    <div className="w-96">
                      <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">
                        Domain
                        <SuperUserTag />
                      </div>
                      <div>
                        <DomainIdField
                          value={formik.values.domain_id}
                          onChange={(domainId) => formik.setFieldValue('domain_id', domainId)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="w-96">
                      <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">
                        Platform
                        <SuperUserTag />
                      </div>
                      <div>
                        <PlatformField
                          value={formik.values.platform}
                          onChange={(domainId) => formik.setFieldValue('platform', domainId)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="w-96">
                      <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">
                        Apps
                        <SuperUserTag />
                      </div>
                      <div>
                        {(account.apps || []).length ? (
                          <ul>
                            {(account.apps || []).map((appId: string) => {
                              const name = appId === '00c4270e50b74f19e5e88030668a1aef' ? 'Thinkific' : `? (${appId})`;
                              return <li key={appId}>{name}</li>;
                            })}
                          </ul>
                        ) : (
                          <em>None</em>
                        )}
                      </div>
                    </div>
                  </div>
                </HasPermission>
              </div>
            </div>

            <div className="w-full border-b border-gray-100 mt-8 mb-10 pb-4 flex flex-row-reverse items-end">
              <PrimaryButton disabled={!formik.isValid || accountMutation.isLoading} className="ml-3" submit>
                {t('save')}
              </PrimaryButton>
              <Button onClick={() => formik.resetForm()}>{t('discard')}</Button>
            </div>
          </form>
        </HasAccountPermission>

        <BillingContentForOwner />
        <HasPermission checker={isDomainAdmin}>
          <ManagedBillingContent account={account} />
        </HasPermission>

        <HasPermission checker={isDomainAdmin}>
          <DeleteAccountContent account={account} />
        </HasPermission>
      </div>
    </AccountSettingsPageLayout>
  );
};

const BillingContentForOwner = () => {
  const account = useAccount();
  const permChecker = usePermissionChecker();
  const isUsingStripe = account.payment_type === AccountPaymentType.Subv2;
  const useSelfBilling = hasPolicy(account, 'use_self_billing');
  const canManageBilling = permChecker.hasAccountPermission(Permission.ManageBilling);

  // A user should be able to manage the billing to see this. This applies to super user and domain admins.
  if (!canManageBilling) {
    return null;
  }

  if (isUsingStripe) {
    return <BillingContentStripeManage />;
  } else if (useSelfBilling) {
    if (isTrialing(account)) {
      return <BillingContentUpgrade />;
    }
    return null;
  }

  return null;
};

const useCustomerPortalMutation = () => {
  const repo = useRepo();
  const account = useAccount();
  return useMutation(
    async () => {
      return repo.createAccountCustomerPortalSession(account.id);
    },
    {
      onSuccess: (data) => {
        window.location.href = data.session_url;
      },
    }
  );
};

const BillingContentLayout: React.FC<{ error?: any }> = ({ error, children }) => {
  const { t } = useTranslation();
  return (
    <div className="border-b border-gray-100 mb-8 pb-8">
      <h2 className="text-lg font-medium mb-4">{t('billing.billing')}</h2>
      {error ? (
        <div className="my-4">
          <NotificationError>{getErrorMessage(error)}</NotificationError>
        </div>
      ) : null}
      <div>{children}</div>
    </div>
  );
};

const BillingContentStripeManage = () => {
  const { t } = useTranslation();
  const mutation = useCustomerPortalMutation();
  return (
    <BillingContentLayout error={mutation.error}>
      <PrimaryButton disabled={mutation.isLoading || mutation.isSuccess} onClick={() => mutation.mutate()}>
        {t('billing.manage')}
      </PrimaryButton>
    </BillingContentLayout>
  );
};

const BillingContentUpgrade = () => {
  const { t } = useTranslation();
  return (
    <BillingContentLayout>
      <LinkPrimaryButton to={'/plans'}>{t('billing.upgrade')}</LinkPrimaryButton>
    </BillingContentLayout>
  );
};

const usePlans = () => {
  const repo = useRepo();
  const user = useUser();
  return useQuery(['billing', 'plans'], () => repo.getBillingPlans(), { enabled: isSuperUser(user) });
};

const ManagedBillingContentStripe = () => {
  const [show, setShow] = useState(false);
  const { refreshAccount } = useContext(AccountContext);
  const account = useAccount();
  const repo = useRepo();
  const plansQuery = usePlans();
  const { t } = useTranslation();

  const mutation = useMutation(
    async ({ overrides }: { overrides: AccountPolicyOverrides }) => {
      return repo.setAccountPolicyOverrides(account.id, overrides);
    },
    {
      onSuccess: () => {
        refreshAccount();
      },
    }
  );

  const nOverrides = Object.values(account.policy_overrides || {}).filter((x) => typeof x !== 'undefined').length;
  const plan = plansQuery.data?.find((plan) => plan.id === account.billing_plan);
  return (
    <div className="flex-grow">
      <div className="">
        <h2 className="text-lg font-medium mb-4">Billing admin</h2>
        <div>
          <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">Plan</div>
          <div className="flex gap-4">
            <div>{plan?.name || account.billing_plan || '?'}</div>
            <div>
              <Button small onClick={() => setShow(true)}>
                Edit {nOverrides ? `(${nOverrides})` : ''}
              </Button>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">Features/Quotas</div>
          <AccountPolicyDisplay account={account} />
        </div>
      </div>
      {show ? (
        <AccountPolicyOverridesModal
          account={account}
          overrides={account.policy_overrides}
          planPolicy={plan?.policy}
          isSaving={mutation.isLoading}
          saveButtonText={t('save')}
          onCancel={() => setShow(false)}
          onSave={(overrides) => {
            mutation.mutate(
              { overrides },
              {
                onSuccess: () => {
                  refreshAccount();
                },
                onSettled: () => {
                  setShow(false);
                },
              }
            );
          }}
        />
      ) : null}
    </div>
  );
};

const managedBillingSchema = yup
  .object({
    paymentType: yup.object({ value: yup.string().required() }).required(),
    expiry: yup.date().required(),
  })
  .required();

const ManagedBillingContent: React.FC<{ account: Account }> = ({ account }) => {
  const [showEditPlan, setShowEditPlan] = useState(false);
  const { t } = useTranslation();
  const user = useUser();
  const paymentTypeOptions = useMemo(
    () =>
      [
        { label: t('paymentTypeAnnual'), value: AccountPaymentType.Annual },
        { label: t('paymentTypeTrial'), value: AccountPaymentType.Trial },
      ].filter(Boolean) as { label: string; value: AccountPaymentType }[],
    [] // eslint-disable-line
  );
  const repo = useRepo();

  const plansQuery = usePlans();
  const plansOptions = useMemo(
    () =>
      plansQuery.data?.map((plan) => ({
        label: plan.name,
        value: plan.id,
      })) || [],
    [plansQuery.data]
  );

  const { refreshAccount } = useContext(AccountContext);
  const mutation = useMutation(
    ({
      paymentType,
      expiry,
      expiryStrict,
      plan,
      overrides,
    }: {
      paymentType: AccountPaymentType;
      expiry: number;
      expiryStrict: boolean;
      plan: string;
      overrides: AccountPolicyOverrides;
    }) => {
      return repo.setAccountBilling(account.id, paymentType, expiry, expiryStrict, plan, overrides);
    },
    {
      onSuccess: () => {
        refreshAccount();
        broadcastSuccessToast(t('settingsSaved'));
      },
    }
  );

  const superUser = isSuperUser(user);
  const domainAdmin = isDomainAdminOf(user, account);
  if (!superUser && !domainAdmin) {
    return null;
  }

  if (account.payment_type === AccountPaymentType.Subv2) {
    return <ManagedBillingContentStripe />;
  }

  if (account.payment_type !== AccountPaymentType.Annual && account.payment_type !== AccountPaymentType.Trial) {
    return null;
  }

  return (
    <div className="flex-grow">
      <div className="">
        <h2 className="text-lg font-medium mb-4">Billing admin</h2>
        <Formik
          enableReinitialize
          initialValues={{
            paymentType: paymentTypeOptions.find((p) => p.value === account.payment_type) as DropdownSelectOption,
            plan: plansOptions.find((p) => p.value === account.billing_plan) as DropdownSelectOption,
            overrides: (account.policy_overrides || {}) as AccountPolicyOverrides,
            expiry: fromUnixTime(account.expiry),
            expiryStrict: Boolean(account.expiry_strict),
          }}
          onSubmit={(values, formik) => {
            mutation.mutate(
              {
                ...values,
                expiry: getUnixTime(values.expiry),
                paymentType: values.paymentType?.value as AccountPaymentType,
                plan: values.plan?.value,
              },
              {
                onError: () => {
                  // TODO Handle error.
                  formik.setSubmitting(false);
                },
              }
            );
          }}
          validationSchema={managedBillingSchema}
          validateOnMount
        >
          {({ dirty, isValid, initialValues, values, setFieldValue, handleReset }) => {
            const showPlans = values.paymentType.value !== AccountPaymentType.Trial;
            const supportsPlanEdit = true;

            const handlePaymentTypeChange = (paymentOpt: DropdownSelectOption) => {
              setFieldValue('paymentType', paymentOpt);
              if (initialValues.paymentType === paymentOpt) {
                setFieldValue('plan', initialValues.plan);
                setFieldValue('overrides', initialValues.overrides);
              } else if (values.paymentType !== paymentOpt) {
                setFieldValue('overrides', {});
              }
            };
            const handlePlanChange = (planOpt: DropdownSelectOption) => {
              setFieldValue('plan', planOpt);
              if (initialValues.plan === planOpt) {
                setFieldValue('overrides', initialValues.overrides);
              } else if (values.plan !== planOpt) {
                setFieldValue('overrides', {});
              }
            };
            const handleMaxUsersChange = (e: React.ChangeEvent<HTMLInputElement>) => {
              const v = e.target.value.trim().replace(/[^0-9-]/g, '');
              setFieldValue('overrides', {
                ...values.overrides,
                max_users: v !== '' ? Math.max(0, Math.min(100000, parseInt(v, 10) || 0)) : undefined,
              });
            };
            const nOverrides = Object.values(values.overrides || {}).filter((x) => typeof x !== 'undefined').length;
            const canSubmit = isValid && dirty && !mutation.isLoading;
            return (
              <Form className="flex flex-col flex-grow mt-2">
                <div className="w-[50rem]">
                  <div className="flex-grow space-y-8">
                    {isSuperUser(user) && isCoreDomain(user) ? (
                      <div className="grid grid-cols-3 gap-4">
                        <div>
                          <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">
                            {t('subscriptionType')}
                            <SuperUserTag />
                          </div>
                          <div>
                            <DropdownSelect
                              placeholder={t('selectEllipsis')}
                              options={paymentTypeOptions}
                              onChange={handlePaymentTypeChange}
                              selected={values.paymentType}
                            />
                          </div>
                        </div>
                        {showPlans ? (
                          <div>
                            <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">
                              Plan <SuperUserTag />
                            </div>
                            <div>
                              <DropdownSelect
                                disabled={values.paymentType?.value !== AccountPaymentType.Annual}
                                placeholder={t('selectEllipsis')}
                                options={plansOptions}
                                onChange={handlePlanChange}
                                selected={values.plan}
                              />
                            </div>
                          </div>
                        ) : null}
                        {supportsPlanEdit ? (
                          <div>
                            <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">
                              Customise <SuperUserTag />
                            </div>
                            <div>
                              <Button onClick={() => setShowEditPlan(true)}>Edit {nOverrides ? `(${nOverrides})` : ''}</Button>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      <div className="grid grid-cols-3 gap-4">
                        <label>
                          <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('players')}</div>
                          <div>
                            <Input
                              name="maxUsers"
                              min={0}
                              max={100000}
                              onChange={handleMaxUsersChange}
                              value={values.overrides.max_users || ''}
                            />
                          </div>
                        </label>
                      </div>
                    )}

                    <div className="grid grid-cols-3 gap-4">
                      <div>
                        <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('expirationDate')}</div>
                        <div className="w-128">
                          <div className="flex items-center gap-4">
                            <div className="w-1/2">
                              <DatePicker
                                value={values.expiry}
                                onChange={(date) => setFieldValue('expiry', date)}
                                className="block w-full text-sm border border-gray-300 rounded-md shadow-sm"
                              />
                            </div>
                            {values.paymentType.value !== AccountPaymentType.Trial ? (
                              <div>
                                <label className="flex items-center text-gray-700 text-sm">
                                  <Field type="checkbox" as={Checkbox} name="expiryStrict" />
                                  <div className="ml-3">{t('lockAccountWhenPassed')}</div>
                                </label>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {supportsPlanEdit && showEditPlan ? (
                  <AccountPolicyOverridesModal
                    account={account}
                    overrides={values.overrides}
                    planPolicy={plansQuery.data?.find((plan) => plan.id === values.plan?.value)?.policy}
                    onCancel={() => setShowEditPlan(false)}
                    onSave={(overrides) => {
                      setShowEditPlan(false);
                      setFieldValue('overrides', overrides);
                    }}
                  />
                ) : null}

                <IsSuperUser>
                  <div className="mt-8">
                    <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">
                      Features/Quotas
                      <SuperUserTag />
                    </div>
                    <AccountPolicyDisplay account={account} />
                  </div>
                </IsSuperUser>

                <div className="w-full border-b border-gray-100 mt-8 mb-10 pb-4 flex flex-row-reverse items-end">
                  <PrimaryButton disabled={!canSubmit} className="ml-3" submit>
                    {t('save')}
                  </PrimaryButton>
                  <Button onClick={handleReset}>{t('discard')}</Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

const policyOverridesWhiteList: any = {
  max_dashboard_users: 'Max admins',
  max_items_per_store: 'Max items per store',
  max_items: 'Max items',
  max_sections: 'Max teams',
  max_stores: 'Max stores',
  max_users: 'Max players',
  use_api: 'Use API',
  use_branding: 'Use branding',
  use_l10n: 'Use localisation',
  use_reporting: 'Use reporting',
  use_shipping_integration: 'Use shipping integration',
  use_store: 'Use store',
  use_tickets: 'Use tickets',
  use_tickets_cost: 'Use tickets cost',
  use_voucher_method: 'Use vouchers',
};

const AccountPolicyDisplay = ({ account }: { account: Account }) => {
  return (
    <div className="text-xs max-w-sm">
      {Object.entries(account.policy)
        .filter(([key]) => key in policyOverridesWhiteList)
        .sort(([k1], [k2]) => (k1 < k2 ? -1 : 1))
        .map(([key, value]) => {
          const name = policyOverridesWhiteList[key] || 'key';
          return (
            <div key={key} className="grid grid-cols-2 gap-2 even:bg-gray-50">
              <div className="mb-1">
                <div>{name}</div>
                <div className="text-[0.6rem] italic text-gray-500">{key}</div>
              </div>
              <div>
                <AccountPolicyDisplayValue>{value}</AccountPolicyDisplayValue>
              </div>
            </div>
          );
        })}
    </div>
  );
};

const AccountPolicyDisplayValue: React.FC = ({ children }) => {
  const { t } = useTranslation();
  let displayValue: string = (children || '').toString();
  if (typeof children === 'number') {
    displayValue = children < 0 ? t('unlimited') : children.toString();
  } else if (typeof children === 'boolean') {
    displayValue = children ? t('yes') : t('no');
  }
  return <>{displayValue}</>;
};

const AccountPolicyOverridesModal = ({
  account,
  overrides,
  planPolicy,
  isSaving,
  saveButtonText,
  onSave,
  onCancel,
}: {
  account: Account;
  overrides?: AccountPolicyOverrides;
  planPolicy?: AccountPolicy;
  saveButtonText?: string;
  onSave: (overrides: AccountPolicyOverrides) => void;
  isSaving?: boolean;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();
  const [state, setState] = useState({ ...overrides });

  useEffect(() => setState({ ...overrides }), [overrides]);

  const handleSave = () => onSave(state);

  const makeChangeHandler = (policy: keyof AccountPolicyOverrides) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      const fieldVal = e.target.value;
      setState({ ...state, [policy]: fieldVal });
    };
  };

  const makeChangeFinalHandler = (policy: keyof AccountPolicyOverrides) => {
    const currentValue = account.policy[policy];
    const isBool = typeof currentValue === 'boolean';
    return (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
      const fieldVal = e.target.value;
      let finalValue;
      if (isBool) {
        if (fieldVal === '0') {
          finalValue = false;
        } else if (fieldVal === '1') {
          finalValue = true;
        }
      } else {
        const tmp = parseInt(fieldVal, 10);
        if (!isNaN(tmp)) {
          finalValue = Math.max(-1, tmp);
        }
      }

      setState({ ...state, [policy]: finalValue });
    };
  };

  return (
    <ActionModal onCancel={onCancel} title={'Customise plan'}>
      <div>
        <div>
          {Object.entries(policyOverridesWhiteList).map(([policy, label]: [any, any]) => {
            const currentValue = account.policy[policy as keyof AccountPolicyOverrides];
            const isBool = typeof currentValue === 'boolean';
            const onChangeHandler = makeChangeHandler(policy);
            const onChangeFinalHandler = makeChangeFinalHandler(policy);
            const value = state[policy as keyof AccountPolicyOverrides];
            const hasValue = typeof value !== 'undefined';

            return (
              <div key={policy} className={classNames('grid gap-2 text-sm', planPolicy ? 'grid-cols-3' : 'grid-cols-2')}>
                <div className="mb-1">
                  <div>{label}</div>
                  <div className="text-[0.6rem] italic text-gray-500">{policy}</div>
                </div>
                {planPolicy ? (
                  <div className="text-xs text-gray-500 flex items-center">
                    Plan:{' '}
                    <AccountPolicyDisplayValue>{planPolicy[policy as keyof AccountPolicyOverrides]}</AccountPolicyDisplayValue>
                  </div>
                ) : null}
                <div>
                  {isBool ? (
                    <select
                      onChange={onChangeFinalHandler}
                      className={classNames(
                        'block w-full text-sm  rounded-md shadow-sm disabled:bg-gray-100',
                        false // hasError && !props.disabled
                          ? 'placeholder-red-300 text-red-900 border-red-500 focus:border-red-500 focus:ring-red-500'
                          : 'border-gray-300'
                      )}
                      value={hasValue ? (value ? '1' : '0') : ''}
                    >
                      <option value="">Unset</option>
                      <option value="1">{t('yes')}</option>
                      <option value="0">{t('no')}</option>
                    </select>
                  ) : null}
                  {!isBool ? (
                    <Input
                      type="text"
                      value={hasValue ? value.toString() : ''}
                      onChange={onChangeHandler}
                      onBlur={onChangeFinalHandler}
                      size={5}
                    />
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
        <div className="text-xs my-4 text-gray-500 text-right">Use -1 for unlimited. Remove value to unset.</div>
      </div>
      <ActionModalButtons
        onCancel={onCancel}
        confirmButtonText={saveButtonText || t('continue')}
        onConfirm={handleSave}
        confirmButtonDisabled={isSaving}
      />
    </ActionModal>
  );
};

const DomainIdField: React.FC<{ value: string; onChange: (domainId: string) => void }> = ({ value, onChange }) => {
  const repo = useRepo();
  const query = useQuery(['domains'], () => repo.getDomains());
  const options = useMemo(() => {
    return query?.data?.map((domain) => ({ value: domain.id, label: `${domain.name} (${domain.id})` }));
  }, [query.data]);

  if (!query.isSuccess || !options) {
    return <>{value}</>;
  }

  const option = options.find((option) => option.value === value);
  const handleChange = (option: { value: string }) => onChange(option.value);
  return <DropdownSelect options={options} selected={option} onChange={handleChange} placeholder="Select domain" />;
};

const PlatformField: React.FC<{ value?: string; onChange: (platform: string) => void }> = ({ value, onChange }) => {
  const query = usePlatforms();
  const options = useMemo(() => {
    return query?.data?.map((platform) => ({ value: platform.id, label: `${platform.name}` }));
  }, [query.data]);

  if (!query.isSuccess || !options) {
    return <>{value}</>;
  }

  const option = options.find((option) => option.value === value);
  const handleChange = (option: { value: string }) => onChange(option.value);
  const handleClear = () => onChange('');
  return (
    <DropdownSelect
      clearable
      options={options}
      selected={option}
      onChange={handleChange}
      placeholder="Select platform"
      onClear={handleClear}
    />
  );
};

const DeleteAccountContent: React.FC<{ account: Account }> = ({ account }) => {
  const { t } = useTranslation();
  const [confirm, setConfirm] = useState(false);
  const repo = useRepo();
  const queryClient = useQueryClient();
  const history = useHistory();
  const { refreshUser } = useContext(AuthContext);
  const { unsetAccount } = useContext(AccountContext);

  const mutation = useMutation<unknown, unknown, { accountId: string }>(({ accountId }) => {
    return repo.deleteAccount(accountId);
  });

  const handleDelete = () => {
    setConfirm(false);
    mutation.mutate(
      { accountId: account.id },
      {
        onSuccess: async () => {
          queryClient.invalidateQueries('accounts');
          await refreshUser();
          history.push('/switch');
          unsetAccount();
          broadcastSuccessToast(t('accountDeleted'));
        },
      }
    );
  };

  return (
    <div className="border-b border-gray-100 mb-8 pb-8">
      <h2 className="text-lg font-medium mb-4">{t('deleteAccount')}</h2>
      {mutation.isError ? (
        <div className="my-4">
          <NotificationError>{getErrorMessage(mutation.error)}</NotificationError>
        </div>
      ) : null}
      <div>
        <DangerButton disabled={mutation.isLoading || mutation.isSuccess} onClick={() => setConfirm(true)}>
          {t('deleteAccount')}
        </DangerButton>
      </div>

      {confirm ? (
        <ConfirmModal
          title={t('areYouSure')}
          message={t('deleteAccountConfirm')}
          onConfirm={handleDelete}
          onCancel={() => setConfirm(false)}
        />
      ) : null}
    </div>
  );
};

const TicketsEarningPercentageDropdown: React.FC<{ value?: number; onChange: (n: number) => void; disabled?: boolean }> = ({
  value = 0,
  onChange,
  disabled,
}) => {
  const options = useMemo(() => {
    return [0, 10, 20, 25, 33.33, 50, 66.66, 100, 150, 200, 300, 500, 1000].map((value) => ({
      value: Math.floor(value).toString(),
      label: `${value}%`,
    }));
  }, []);
  const option = options.find((option) => option.value === value.toString());
  return (
    <DropdownSelect
      options={options}
      selected={option}
      disabled={disabled}
      placeholder=""
      onChange={(option) => onChange(Math.max(0, parseInt(option.value, 10)))}
    />
  );
};

export default AccountSettingsPage;
