import { ChevronDoubleUpIcon, ChartBarIcon, UserIcon, UsersIcon, GiftIcon } from '@heroicons/react/24/outline';
import { differenceInCalendarDays } from 'date-fns';
import { fromUnixTime } from 'date-fns/esm';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteProps, useHistory, useLocation } from 'react-router';
import { Link, LinkProps, matchPath } from 'react-router-dom';
import { hasPolicy, isTrialExpired, isTrialing, useAccount } from '../../lib/account';
import { usePermissionChecker } from '../../lib/hooks';
import { useRecentAccounts } from '../../lib/queries';
import { Permission } from '../../lib/types';
import { classNames } from '../../lib/utils';
import { Button } from '../Buttons';
import StoreIcon from '../icons/StoreIcon';
import UserAdminIcon from '../icons/UserAdminIcon';
import Logo from '../Logo';
import PoweredBy from '../PoweredBy';
import AccountDropdown from '../widgets/AccountDropdown';
import UserDropdown from '../widgets/UserDropdown';
import { hasAnywhereInAccountPermission, isSuperUser } from '../../lib/perms';
import { useUser } from '../../lib/auth';
import { SuperUserTag } from '../widgets/Tag';

type RouterRouteMatch = string | string[] | RouteProps;

const SidebarLink: React.FC<
  Exclude<LinkProps, 'component'> & {
    strict?: boolean;
    icon: React.ComponentType<React.ComponentProps<'svg'>>;
    count?: number;
    routeMatches?: RouterRouteMatch[];
  }
> = (props) => {
  var location = useLocation();
  const to = typeof props.to === 'string' ? props.to : '@@@@@@';
  const routeMatches: RouterRouteMatch[] = [{ path: to, strict: props.strict } as RouterRouteMatch].concat(
    props.routeMatches || []
  );
  const isActive = routeMatches.some((path) => {
    return Boolean(matchPath(location.pathname, path));
  });
  const Icon = props.icon;

  return (
    <Link
      to={props.to}
      className={classNames(
        isActive ? 'font-bold bg-gray-700 text-gray-50' : 'font-medium text-gray-50 hover:bg-gray-500 hover:text-gray-50',
        'group flex items-center px-2 py-2 text-sm rounded-md'
      )}
      aria-current={isActive ? 'page' : undefined}
    >
      <Icon
        className={classNames(isActive ? 'text-gray-400' : 'text-gray-50 group-hover:text-gray-50', 'mr-3 flex-shrink-0 h-6 w-6')}
        aria-hidden="true"
      />
      <span className="flex-1">{props.children}</span>
      {props.count ? (
        <span
          className={classNames(
            isActive ? 'bg-white' : 'bg-gray-100 group-hover:bg-gray-200',
            'ml-3 inline-block py-0.5 px-3 text-xs font-medium rounded-full'
          )}
        >
          {props.count}
        </span>
      ) : null}
    </Link>
  );
};

const SideBarMenu = () => {
  const { t } = useTranslation();
  const user = useUser();
  const account = useAccount();
  const permChecker = usePermissionChecker();

  const navigation = [
    {
      name: t('players'),
      href: '/players',
      icon: UserIcon,
      count: 0,
      routeMatches: [{ path: '/players' }],
      can: permChecker.hasAnywhereInAccountPermission(Permission.ReadPlayer),
    },
    {
      name: t('teams'),
      href: '/teams',
      icon: UsersIcon,
      routeMatches: [{ path: '/player/:playerId' }, { path: '/team' }, { path: '/teams' }],
      can: permChecker.hasInAnyTeamPermission(Permission.ReadTeam),
    },
    {
      name: t('stores'),
      href: '/stores',
      icon: StoreIcon,
      routeMatches: [{ path: '/store' }, { path: '/stores' }, { path: '/item' }, { path: '/product' }],
      can: hasPolicy(account, 'use_store') && permChecker.hasInAnyStorePermission(Permission.ReadStore),
    },
    {
      name: t('levels'),
      href: '/levels',
      icon: ChevronDoubleUpIcon,
      can: permChecker.hasAccountPermission(Permission.ManageLevels),
    },
    {
      name: t('orders'),
      href: '/orders',
      icon: GiftIcon,
      can:
        hasPolicy(account, 'use_shipping_integration') &&
        hasPolicy(account, 'use_store') &&
        permChecker.hasAnywhereInAccountPermission(Permission.ReadOrder),
    },
    {
      name: t('reporting'),
      href: '/reports/overview', // That page will redirect if permissions are lacking.
      icon: ChartBarIcon,
      routeMatches: [{ path: '/reports' }, { path: '/transactions' }],
      can:
        permChecker.hasAnywhereInAccountPermission(Permission.ReadTransaction) ||
        permChecker.hasInAnyTeamPermission(Permission.ReadReport),
    },
    {
      name: t('admins'),
      href: '/members',
      icon: UserAdminIcon,
      routeMatches: [{ path: '/member/:memberId' }],
      can: permChecker.hasAnywhereInAccountPermission(Permission.ReadMember),
    },
  ];
  return (
    <nav className="flex-1 space-y-1">
      {navigation
        .filter((item) => item.can)
        .map((item) => (
          <SidebarLink key={item.href} to={item.href} icon={item.icon} routeMatches={item.routeMatches}>
            {item.name}
          </SidebarLink>
        ))}
    </nav>
  );
};

const TrialUpgradeNotice = () => {
  const account = useAccount();
  const { t } = useTranslation();
  const history = useHistory();
  const permChecker = usePermissionChecker();

  if (!isTrialing(account) || isTrialExpired(account)) {
    return null;
  }

  const days = differenceInCalendarDays(fromUnixTime(account.expiry), new Date());
  let msg = t('yourTrialExpiresToday');
  if (days > 0) {
    msg = t('yourTrialExpiresInDays', { days });
  }

  return (
    <div className="inset-x-0 top-0 flex justify-center z-20 fixed pointer-events-none">
      <div className="rounded bg-yellow-300 px-3 py-1 text-sm mt-3 flex items-center gap-2 pointer-events-auto">
        <div>{msg}</div>
        {hasPolicy(account, 'use_self_billing') && permChecker.hasAccountPermission(Permission.ManageBilling) ? (
          <Button small onClick={() => history.push('/plans')}>
            {t('billing.upgrade')}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

const LayoutWithSidebar: React.FC = ({ children }) => {
  const account = useAccount();
  const query = useRecentAccounts(account.id);

  return (
    <div className="w-full h-full flex flex-col">
      <div className="h-16 min-h-16 shadow flex fixed inset-x-0 z-10 bg-white">
        <div className="pt-5 pl-4 flex-grow">
          <Logo />
        </div>
        <div className="p-4 flex-shrink-0">
          <UserDropdown />
        </div>
      </div>
      <TrialUpgradeNotice />
      <div className="flex flex-grow relative">
        <div className="flex flex-col bg-gray-600 text-white w-[255px] pt-4 flex-shrink-0 px-2 fixed inset-y-0 top-16 z-10">
          <div className="mb-2">
            <AccountDropdown accountName={account.name} accounts={query.recentAccounts} />
          </div>
          <div className="flex flex-grow">
            <SideBarMenu />
          </div>
          <div className="flex justify-center text-center my-3">
            <PoweredBy />
          </div>
        </div>

        <ContentAreaWithSidebar>
          <div className="pt-6 mt-16 mb-10 flex flex-col w-full">{children}</div>
        </ContentAreaWithSidebar>
      </div>
    </div>
  );
};

export const ContentAreaWithSidebar: React.FC<{ className?: string }> = ({ children, className }) => {
  return (
    <div className={classNames('ml-[255px] flex flex-grow px-6 2xl:px-10', className)}>
      <div className="flex w-full min-w-[1026px] 2xl:min-w-auto 3xl:max-w-[1201px] 3xl:mx-auto">{children}</div>
    </div>
  );
};

export default LayoutWithSidebar;
