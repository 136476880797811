import { ArrowLongDownIcon, ArrowLongUpIcon, PlusIcon } from '@heroicons/react/24/solid';
import download from 'downloadjs';
import { isUndefined } from 'lodash';
import { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { UseMutationResult, useMutation } from 'react-query';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { hasTicketsEnabledInTeam, useAccount } from '../../lib/account';
import { usePermissionChecker, useSelection } from '../../lib/hooks';
import { usePlayerCoinsMutation, usePlayerDeleteMutation, usePlayerTicketsMutation } from '../../lib/mutations';
import { ContinuatedQueryObserverResult, useContinuatedQuery } from '../../lib/queries';
import { ContinuableResponse, useRepo } from '../../lib/repository';
import { Permission, Player } from '../../lib/types';
import { Button } from '../Buttons';
import { AddCoinsModal, AddTicketsModal, DeletePlayerModal } from '../Modals';
import Notification from '../Notifications';
import { HasTeamPermission } from '../Permissions';
import { broadcastSuccessToast } from '../Toasts';
import TeamPageLayout, { useTeamPage } from '../layouts/TeamPage';
import { PlayerQuotaWarning } from '../players/QuotaWarning';
import PlayersTable from '../players/Table';
import { PlayersFilters, PlayersFiltersContext, PlayersFiltersProvider } from '../transactions/Filters';
import Dropdown from '../widgets/Dropdown';
import { HasPolicy } from '../Policy';

const PLAYERS_DOWNLOAD_LIMIT = 10000; // Sync with backend.

const useTeamPlayers = (searchTerm?: string, orderBy?: string) => {
  const repo = useRepo();
  const { teamId } = useParams<{ teamId: string }>();

  return useContinuatedQuery(
    ['team-players', teamId, { searchTerm, orderBy }],
    ({ pageParam }) => {
      return repo.getTeamPlayers(teamId, { term: searchTerm }, orderBy, pageParam);
    },
    {
      keepPreviousData: true,
      resetPageDependencies: [searchTerm, orderBy, teamId],
    }
  );
};

const useTeamPlayersExportMutation = (term?: string, orderBy?: string) => {
  const repo = useRepo();
  const { teamId } = useParams<{ teamId: string }>();

  return useMutation(() => repo.exportTeamPlayers(teamId, { term }, orderBy), {
    onSuccess: (data) => {
      download(data, 'players.csv', 'text/csv');
    },
  });
};

const findPlayerWithId = (players: Player[] | undefined, id: string): Player | undefined => {
  if (!players) return;
  return players.find((player) => player.id === id);
};

const TeamPlayerPageContent: React.FC<{
  queryResult: ContinuatedQueryObserverResult<ContinuableResponse<Player>, unknown, any>;
}> = ({ queryResult }) => {
  const { t } = useTranslation();
  const { team } = useTeamPage();
  const account = useAccount();

  const { sorting } = useContext(PlayersFiltersContext);
  const query = queryResult;
  const selection = useSelection(query);
  const [toDeleteItems, setToDeleteItems] = useState<string[]>([]);
  const [toAddCoinsItems, setToAddCoinsItems] = useState<string[]>([]);
  const [toAddTicketsItems, setToAddTicketsItems] = useState<string[]>([]);
  const deleteMutation = usePlayerDeleteMutation(query.queryKey);
  const userCoinsMutation = usePlayerCoinsMutation();
  const userTicketsMutation = usePlayerTicketsMutation();
  const permChecker = usePermissionChecker();
  const hasNoStores = team.stores.length <= 0;

  const handleAddCoins = (coins: number, message?: string, reason?: string) => {
    if (!toAddCoinsItems.length) {
      return;
    }
    setToAddCoinsItems([]);

    // This may not scale well for large number of users.
    toAddCoinsItems.forEach((id) => {
      const player = findPlayerWithId(query.data?.items, id);
      if (!player) return;
      userCoinsMutation.mutate(
        { player, coins, message, reason },
        {
          onSuccess: () => {
            // The callback will be called for the last registered mutation.
            selection.removeFromSelection(toAddCoinsItems);
            broadcastSuccessToast(t('coinsAwarded'));
          },
        }
      );
    });
  };

  const handleAddTickets = (amount: number) => {
    if (!toAddTicketsItems.length) {
      return;
    }
    setToAddTicketsItems([]);

    // This may not scale well for large number of users.
    toAddTicketsItems.forEach((id) => {
      const player = findPlayerWithId(query.data?.items, id);
      if (!player) return;
      userTicketsMutation.mutate(
        { player, amount },
        {
          onSuccess: () => {
            // The callback will be called for the last registered mutation.
            selection.removeFromSelection(toAddTicketsItems);
            broadcastSuccessToast(t('ticketsAwarded'));
          },
        }
      );
    });
  };

  const handleDelete = () => {
    if (!toDeleteItems.length) {
      return;
    }
    setToDeleteItems([]);

    // This may not scale well for large number of users.
    toDeleteItems.forEach((id) => {
      const player = findPlayerWithId(query.data?.items, id);
      if (!player) return;
      deleteMutation.mutate(
        { player },
        {
          onSuccess: () => {
            // The callback will be called for the last registered mutation.
            selection.removeFromSelection(toDeleteItems);
            broadcastSuccessToast(t('playerDeleted', { count: toDeleteItems.length }));
          },
        }
      );
    });
  };

  const actions = [
    {
      label: t('addCoins'),
      onClick: (ids: string[]) => setToAddCoinsItems(ids),
      can: permChecker.hasTeamPermission(team.id, Permission.AwardCoin),
    },
    {
      label: t('addTickets'),
      onClick: (ids: string[]) => setToAddTicketsItems(ids),
      can: hasTicketsEnabledInTeam(account, team) && permChecker.hasTeamPermission(team.id, Permission.AwardCoin),
    },
    {
      label: t('delete'),
      danger: true,
      onClick: (ids: string[]) => setToDeleteItems(ids),
      can: permChecker.hasTeamPermission(team.id, Permission.DeletePlayer),
    },
  ].filter((action) => isUndefined(action.can) || action.can);

  return (
    <>
      <HasPolicy policy="use_store">
        <HasTeamPermission teamId={team.id} perm={Permission.ReadTeamStore}>
          {hasNoStores ? (
            <div className="mb-4">
              <Notification type="warning">
                <Trans t={t} i18nKey="teamNotLinkedToStoresVisitMarket" components={[<Link to={`/team/${team.id}/market`} />]} />
              </Notification>
            </div>
          ) : null}
        </HasTeamPermission>
      </HasPolicy>

      <PlayerQuotaWarning />

      <div className="mb-4">
        <div className="flex items-center space-x-2">
          {actions.length ? (
            <div>
              <Dropdown
                label={t('actions')}
                options={actions.map((action) => ({
                  ...action,
                  disabled: !selection.selectedIds.length,
                  onClick: () => action.onClick(selection.selectedIds),
                }))}
              />
            </div>
          ) : null}
          <div className="w-96">
            <PlayersFilters />
          </div>
        </div>
      </div>
      <div>
        <PlayersTable queryResult={query} selectionResult={selection} sortingResult={sorting} actions={actions} teamView />
      </div>

      {toDeleteItems.length ? (
        <DeletePlayerModal onDelete={handleDelete} onCancel={() => setToDeleteItems([])} count={toDeleteItems.length} />
      ) : null}

      {toAddCoinsItems.length ? (
        <AddCoinsModal onConfirm={handleAddCoins} onCancel={() => setToAddCoinsItems([])} count={toAddCoinsItems.length} />
      ) : null}

      {toAddTicketsItems.length ? (
        <AddTicketsModal onConfirm={handleAddTickets} onCancel={() => setToAddTicketsItems([])} count={toAddTicketsItems.length} />
      ) : null}
    </>
  );
};

const TeamPlayersContainer: React.FC = () => {
  const { filter, sorting } = useContext(PlayersFiltersContext);
  const query = useTeamPlayers(filter.filterTerm, sorting.sortField);
  const exportMutation = useTeamPlayersExportMutation(filter.filterTerm, sorting.sortField);
  return <TeamPlayers queryResult={query} exportMutation={exportMutation} />;
};

const TeamPlayers: React.FC<{
  exportMutation: UseMutationResult<Blob, unknown, void, unknown>;
  queryResult: ContinuatedQueryObserverResult<ContinuableResponse<Player>>;
}> = ({ exportMutation, queryResult }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { isLoading: isDownloading } = exportMutation;
  const totalRecords = queryResult?.data?.total || 0;
  const canDownload = queryResult.isSuccess && totalRecords <= PLAYERS_DOWNLOAD_LIMIT && totalRecords > 0 && !isDownloading;

  return (
    <TeamPageLayout
      buttons={(team) => (
        <>
          <HasTeamPermission teamId={team.id} perm={Permission.CreatePlayer}>
            <Button onClick={() => history.push(`/team/${team.id}/add-player`)} icon={PlusIcon}>
              {t('addPlayer', { count: 1 })}
            </Button>
          </HasTeamPermission>
          <HasTeamPermission teamId={team.id} perm={Permission.AwardCoin}>
            <Button onClick={() => history.push(`/team/${team.id}/import`)} icon={ArrowLongUpIcon}>
              {t('importCoins')}
            </Button>
          </HasTeamPermission>
          <HasTeamPermission teamId={team.id} perm={Permission.ReadPlayer}>
            <Button icon={ArrowLongDownIcon} disabled={!canDownload} onClick={() => exportMutation.mutate()}>
              {t('exportCsv')}
            </Button>
          </HasTeamPermission>
        </>
      )}
    >
      <TeamPlayerPageContent queryResult={queryResult} />
    </TeamPageLayout>
  );
};

const TeamPlayersPage = () => {
  return (
    <PlayersFiltersProvider>
      <TeamPlayersContainer />
    </PlayersFiltersProvider>
  );
};

export default TeamPlayersPage;
