import { Dialog, Transition } from '@headlessui/react';
import { ArrowTopRightOnSquareIcon, PencilIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Field, Form, Formik } from 'formik';
import { isUndefined } from 'lodash';
import React, { Fragment, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router';
import * as yup from 'yup';
import { hasTicketsEnabledInTeam, useAccount } from '../../lib/account';
import { usePermissionChecker } from '../../lib/hooks';
import { getErrorMessage } from '../../lib/i18n';
import { getAccountLevelThresholds, getLevelFromThresholds } from '../../lib/levels';
import {
  usePlayerCoinsMutation,
  usePlayerDeleteMutation,
  usePlayerMutation,
  usePlayerTeamChangeMutation,
  usePlayerTicketsMutation,
} from '../../lib/mutations';
import { getPlayerName } from '../../lib/player';
import { ContinuatedQueryObserverResult, useContinuatedQuery, useTeam } from '../../lib/queries';
import { ContinuableResponse, useRepo } from '../../lib/repository';
import { Permission, Transaction } from '../../lib/types';
import { classNames } from '../../lib/utils';
import { PlayerAvatar } from '../Avatars';
import { AnchorButton, Button, DangerButton, PrimaryButton } from '../Buttons';
import { AddCoinsModal, AddTicketsModal, ChangePlayerTeamModal, DeletePlayerModal } from '../Modals';
import { NotificationError } from '../Notifications';
import { OrdersRequestsTable } from '../OrdersTable';
import { HasImplicitPlayerPermission, HasTeamPermission } from '../Permissions';
import { broadcastErrorToast, broadcastSuccessToast } from '../Toasts';
import CountryDropdown from '../forms/Country';
import Input from '../inputs/Input';
import { Fullscreenable, useFullscreen } from '../layouts/Fullscreen';
import LayoutWithSidebar from '../layouts/WithSidebar';
import { PageHeaderWithBackLabel } from '../layouts/partials/PageHeaders';
import TransactionsFilters, { TransactionFiltersContext, TransactionsFiltersProvider } from '../transactions/Filters';
import TransactionsTable from '../transactions/Table';
import { HasTicketsEnabledInTeam } from '../utils/HasTicketsEnabled';
import Coins, { Tickets } from '../widgets/Coins';
import Dropdown from '../widgets/Dropdown';
import { LevelBadge } from '../widgets/Level';
import { HasPolicy } from '../Policy';

const usePlayer = (playerId: string) => {
  const repo = useRepo();
  return useQuery(['user', playerId], () => {
    return repo.getPlayer(playerId);
  });
};

const LabelledContent: React.FC<{ label: string; className?: string }> = ({ children, label, className }) => {
  return (
    <div className="flex items-center">
      <div className="font-medium">{label}</div>
      <div className={classNames('ml-2', className)}>{children}</div>
    </div>
  );
};

const FieldWrapper: React.FC<{ label: string; htmlFor?: string }> = ({ label, children, htmlFor }) => {
  const LabelWrapper = htmlFor ? React.Fragment : 'label';
  const LabelTag = htmlFor ? 'label' : 'div';
  const labelProps = htmlFor ? { htmlFor } : {};
  return (
    <div className="w-96">
      <LabelWrapper>
        <LabelTag className="leading-5 mb-1 text-gray-700 text-sm font-medium" {...labelProps}>
          {label}
        </LabelTag>
        <div>{children}</div>
      </LabelWrapper>
    </div>
  );
};

const PlayerPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const account = useAccount();
  const [showCoinsModal, setShowCoinsModal] = useState(false);
  const [showTicketsModal, setShowTicketsModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPlayerStore, setShowPlayerStore] = useState(false);
  const [showTeamChangeModal, setShowTeamChangeModal] = useState(false);
  const { playerId } = useParams<{ playerId: string }>();
  const { isLoading, data: player } = usePlayer(playerId);
  const queryClient = useQueryClient();
  const teamQuery = useTeam(player?.school_id || '', { enabled: Boolean(player?.school_id) });
  const coinMutation = usePlayerCoinsMutation();
  const ticketMutation = usePlayerTicketsMutation();
  const deleteMutation = usePlayerDeleteMutation();
  const playerMutation = usePlayerMutation();
  const teamChangeMutation = usePlayerTeamChangeMutation();
  const permChecker = usePermissionChecker();
  const level = useMemo(() => {
    const levelThresholds = getAccountLevelThresholds(account);
    return getLevelFromThresholds(levelThresholds, player?.coins_earned_lifetime || 0);
  }, [player, account]);

  const team = teamQuery.data;
  const teamName = teamQuery.isIdle || teamQuery.isLoading ? undefined : team ? team.name : '?';
  const backUrl = player ? `/team/${player.school_id}` : '/players';

  const actions = player
    ? [
        {
          label: t('addCoins'),
          can: permChecker.hasTeamPermission(player.school_id, Permission.AwardCoin),
          onClick: () => setShowCoinsModal(true),
        },
        {
          label: t('addTickets'),
          can:
            Boolean(team) &&
            hasTicketsEnabledInTeam(account, team!) &&
            permChecker.hasTeamPermission(player.school_id, Permission.AwardCoin),
          onClick: () => setShowTicketsModal(true),
        },
        {
          label: t('openStore'),
          can: permChecker.hasTeamPermission(player.school_id, Permission.ImpersonatePlayer),
          onClick: () => setShowPlayerStore(true),
        },
        {
          label: t('delete'),
          danger: true,
          can: permChecker.hasTeamPermission(player.school_id, Permission.DeletePlayer),
          onClick: () => setShowDeleteModal(true),
        },
      ].filter((action) => isUndefined(action.can) || action.can)
    : [];

  const handleStoreClose = () => {
    setShowPlayerStore(false);
    if (!player) return;
    // Invalidate cache if coins were spent.
    queryClient.invalidateQueries('users');
    queryClient.invalidateQueries(['user', player.id]);
    queryClient.invalidateQueries(['user-orders', player.id]);
    queryClient.invalidateQueries(['user-transactions', player.id]);
    queryClient.invalidateQueries(['transactions', player.account_id]);
  };

  return (
    <LayoutWithSidebar>
      <PageHeaderWithBackLabel
        backLabel={teamName}
        backTo={backUrl}
        buttons={player && actions.length ? <Dropdown right options={actions} label={t('actions')} /> : null}
      >
        {player ? getPlayerName(player) : undefined}
      </PageHeaderWithBackLabel>
      {isLoading || !player ? null : (
        <div>
          <div className="flex">
            <div className="mr-6">
              <PlayerAvatar name={getPlayerName(player)} />
            </div>
            <div className="mr-6">
              <LevelBadge level={level} className="h-20" />
            </div>
            <div className="flex flex-grow">
              <div className="mt-2 space-y-2">
                <div>
                  <LabelledContent label={t('team')}>
                    {!teamName ? (
                      <div className="h-7 flex items-center">
                        <div className="content-loading h-4 w-24" />
                      </div>
                    ) : permChecker.hasTeamPermission(player.school_id, Permission.SetPlayerTeam) ? (
                      <button
                        type="button"
                        className="flex items-center bg-white border border-gray-300 shadow-sm rounded-3xl py-[3px] px-3.5 text-sm
                      font-medium hover:bg-gray-50"
                        onClick={() => setShowTeamChangeModal(true)}
                      >
                        <div>{teamName}</div>
                        <PencilIcon className="h-3.5 w-3.5 ml-1.5 text-gray-700" aria-hidden="true" />
                      </button>
                    ) : (
                      teamName
                    )}
                  </LabelledContent>
                </div>
                <div className="flex space-x-8">
                  <LabelledContent className="font-medium" label={t('coins')}>
                    <Coins amount={player.coins} />
                  </LabelledContent>
                  <LabelledContent className="font-medium" label={t('totalCoins')}>
                    <Coins amount={player.coins_earned_lifetime} />
                  </LabelledContent>
                  {team ? (
                    <HasTicketsEnabledInTeam team={team}>
                      <LabelledContent className="font-medium" label={t('tickets')}>
                        <Tickets amount={player.tickets} />
                      </LabelledContent>
                    </HasTicketsEnabledInTeam>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <HasTeamPermission teamId={player.school_id} perm={Permission.UpdatePlayer}>
            <div className="mt-12 border-b border-gray-100 pb-4">
              <Formik
                initialValues={{
                  firstname: player.firstname || '',
                  lastname: player.lastname || '',
                  email: player.email || '',
                  shipping: player.shipping || {},
                }}
                onSubmit={(data, formik) => {
                  playerMutation.mutate(
                    { player, data },
                    {
                      onSettled: (...args) => {
                        formik.setSubmitting(false);
                      },
                      onSuccess: () => {
                        broadcastSuccessToast(t('informationSaved'));
                        formik.resetForm({ values: data });
                      },
                    }
                  );
                }}
                validationSchema={yup
                  .object({ firstname: yup.string(), lastname: yup.string(), email: yup.string().email() })
                  .required()}
              >
                {(form) => {
                  const canSubmit = form.dirty && form.isValid && !form.isSubmitting;
                  return (
                    <Form className="flex flex-col flex-grow">
                      {playerMutation.isError ? (
                        <div className="mb-4">
                          <NotificationError>{getErrorMessage(playerMutation.error)}</NotificationError>
                        </div>
                      ) : null}
                      <div className="flex-grow space-y-8">
                        <div className="flex space-x-6">
                          <FieldWrapper label={t('firstName')}>
                            <Field as={Input} name="firstname" />
                          </FieldWrapper>
                          <FieldWrapper label={t('lastName')}>
                            <Field as={Input} name="lastname" />
                          </FieldWrapper>
                        </div>
                        <FieldWrapper label={t('email')}>
                          <Field as={Input} name="email" type="email" />
                        </FieldWrapper>
                        <h3 className="text-lg font-semibold mb-4">{t('shippingDetails')}</h3>
                        <div className="flex space-x-6">
                          <FieldWrapper label={t('locationName')}>
                            <Field as={Input} name="shipping.location_name" type="text" />
                          </FieldWrapper>
                          <FieldWrapper label={t('locationId')}>
                            <Field as={Input} name="shipping.location_id" type="text" />
                          </FieldWrapper>
                        </div>
                        <FieldWrapper label={t('address')}>
                          <div className="space-y-2">
                            <Field as={Input} name="shipping.address_line_1" type="text" placeholder={t('addressLine1')} />
                            <Field as={Input} name="shipping.address_line_2" type="text" placeholder={t('addressLine2')} />
                          </div>
                        </FieldWrapper>
                        <div className="flex space-x-6">
                          <FieldWrapper label={t('city')}>
                            <Field as={Input} name="shipping.city" type="text" />
                          </FieldWrapper>
                          <FieldWrapper label={t('zipPostCode')}>
                            <Field as={Input} name="shipping.postcode" type="text" />
                          </FieldWrapper>
                        </div>
                        <div className="flex space-x-6">
                          <FieldWrapper label={t('stateProvince')}>
                            <Field as={Input} name="shipping.state" type="text" />
                          </FieldWrapper>
                          <FieldWrapper label={t('country')} htmlFor="id-country-does-not-exist">
                            <CountryDropdown
                              allowEmpty
                              onChange={(v) => form.setFieldValue('shipping.country', v)}
                              selected={form.values?.shipping?.country?.toLowerCase()}
                            />
                          </FieldWrapper>
                        </div>
                      </div>

                      <div className="w-full border-t border-gray-100 mt-8 pt-4 flex">
                        <div className="flex-grow">
                          <HasTeamPermission teamId={player.school_id} perm={Permission.DeleteStore}>
                            <DangerButton onClick={() => setShowDeleteModal(true)}>{t('delete')}</DangerButton>
                          </HasTeamPermission>
                        </div>
                        <div className="flex flex-row-reverse items-end">
                          <PrimaryButton disabled={!canSubmit} className="ml-3" submit>
                            {t('save')}
                          </PrimaryButton>
                          <Button onClick={() => form.resetForm()}>{t('discard')}</Button>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </HasTeamPermission>

          <HasPolicy policy="use_store">
            <HasImplicitPlayerPermission player={player} perm={Permission.ReadOrder}>
              <div className="mt-6 border-b border-gray-100">
                <h2 className="font-semibold text-xl leading-8">{t('orders')}</h2>
                <div className="flex flex-col mt-2 min-h-[4rem] mb-6">
                  <PlayerOrders playerId={player.id} />
                </div>
              </div>
            </HasImplicitPlayerPermission>
          </HasPolicy>

          <HasImplicitPlayerPermission player={player} perm={Permission.ReadTransaction}>
            <div className="my-6">
              <TransactionsFiltersProvider>
                <PlayerTransactionsContainer teamId={player.school_id} playerId={player.id} />
              </TransactionsFiltersProvider>
            </div>
          </HasImplicitPlayerPermission>

          {showCoinsModal ? (
            <AddCoinsModal
              onConfirm={(coins, message, reason) => {
                coinMutation.mutate(
                  { player, coins, message, reason },
                  {
                    onSuccess: () => {
                      broadcastSuccessToast(t('coinsAwarded'));
                    },
                  }
                );
                setShowCoinsModal(false);
              }}
              onCancel={() => setShowCoinsModal(false)}
              count={1}
            />
          ) : null}

          {showTicketsModal ? (
            <AddTicketsModal
              onConfirm={(amount) => {
                ticketMutation.mutate(
                  { player, amount },
                  {
                    onSuccess: () => {
                      broadcastSuccessToast(t('ticketsAwarded'));
                    },
                  }
                );
                setShowTicketsModal(false);
              }}
              onCancel={() => setShowTicketsModal(false)}
            />
          ) : null}

          {showDeleteModal ? (
            <DeletePlayerModal
              onDelete={() => {
                deleteMutation.mutate(
                  { player },
                  {
                    onSuccess: () => {},
                  }
                );
                setShowDeleteModal(false);
                history.push(backUrl);
                broadcastSuccessToast(t('playerDeleted', { count: 1 }));
              }}
              onCancel={() => setShowDeleteModal(false)}
            />
          ) : null}

          {showPlayerStore ? <PlayerStoreModal onClose={handleStoreClose} playerId={player.id} /> : null}

          {showTeamChangeModal ? (
            <ChangePlayerTeamModal
              player={player}
              onCancel={() => setShowTeamChangeModal(false)}
              onConfirm={(teamId) => {
                if (teamId === player.school_id) {
                  return;
                }
                teamChangeMutation.mutate(
                  { player, teamId },
                  {
                    onSuccess: () => {
                      broadcastSuccessToast(t('teamChanged'));
                    },
                    onError: (err) => {
                      broadcastErrorToast(getErrorMessage(err));
                    },
                  }
                );
                setShowTeamChangeModal(false);
              }}
            />
          ) : null}
        </div>
      )}
    </LayoutWithSidebar>
  );
};

const usePlayersOrders = (playerId: string) => {
  const repo = useRepo();
  return useContinuatedQuery(
    ['user-orders', playerId],
    ({ pageParam }) => {
      return repo.getPlayerOrders(playerId, undefined, undefined, pageParam);
    },
    {
      keepPreviousData: true,
      resetPageDependencies: [playerId],
    }
  );
};

const PlayerOrders = ({ playerId }: { playerId: string }) => {
  const query = usePlayersOrders(playerId);
  return <OrdersRequestsTable queryResult={query} showPlayerName={false} />;
};

const usePlayerTransactions = (
  playerId: string,
  filters?: {
    type?: string;
    details?: string;
    dateFrom?: number;
    dateTo?: number;
  },
  orderBy?: string
) => {
  const repo = useRepo();
  const results = useContinuatedQuery(
    ['user-transactions', playerId, filters, orderBy],
    ({ pageParam }) => {
      return repo.getPlayerTransactions(playerId, filters, orderBy, 10, pageParam);
    },
    {
      keepPreviousData: true,
      pageSize: 10,
      resetPageDependencies: [playerId, orderBy, ...Object.values(filters || {})],
    }
  );

  return results;
};

const PlayerTransactionsContainer: React.FC<{ playerId: string; teamId?: string }> = ({ children, playerId, teamId }) => {
  const { timestampFrom, timestampTo, type, details } = useContext(TransactionFiltersContext);
  const query = usePlayerTransactions(playerId, {
    type: type,
    details: details,
    dateFrom: timestampFrom,
    dateTo: timestampTo,
  });

  return (
    <Fullscreenable>
      <PlayerTransactions queryResult={query}>{children}</PlayerTransactions>
    </Fullscreenable>
  );
};

const PlayerTransactions: React.FC<{ queryResult: ContinuatedQueryObserverResult<ContinuableResponse<Transaction>> }> = ({
  queryResult,
}) => {
  const { t } = useTranslation();
  const { isFullscreen, goFullscreen } = useFullscreen();
  const { hasFilters } = useContext(TransactionFiltersContext);

  return (
    <>
      <h2 className="font-semibold text-xl leading-8 mb-3">
        {isFullscreen ? (
          t('transactions')
        ) : (
          <AnchorButton className="inline-flex items-center" onClick={goFullscreen}>
            {t('transactions')}
            <ArrowTopRightOnSquareIcon aria-hidden="true" className="ml-2 h-6 w-6 text-gray-500" />
          </AnchorButton>
        )}
      </h2>
      <TransactionsFilters />
      <div className="mt-4">
        <TransactionsTable queryResult={queryResult} hasFilters={hasFilters} />
      </div>
    </>
  );
};

export const PlayerStoreModal: React.FC<{ playerId: string; onClose: () => void }> = ({ playerId, onClose }) => {
  const { t } = useTranslation();

  const repo = useRepo();
  const query = useQuery(['player', playerId, 'store-login-url'], async () => (await repo.getPlayerStoreLoginUrl(playerId)).url, {
    cacheTime: 0,
    staleTime: 0,
  });

  const handleCancel = () => {
    onClose();
  };

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={handleCancel}>
        <div className="flex min-h-screen text-center outline-none" tabIndex={0}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block align-middle h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-0 scale-95"
            enterTo="opacity-100 translate-y-0 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 scale-100"
            leaveTo="opacity-0 translate-y-0 scale-95"
          >
            <div
              className={classNames(
                'flex m-4 bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-full'
              )}
            >
              <div className="block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={handleCancel}
                >
                  <span className="sr-only">{t('close')}</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="flex-1 flex items-start">
                {query.isLoading || !query.data ? (
                  <div className="p-6">{t('loadingEllipsis')}</div>
                ) : (
                  <iframe src={query.data} title={t('store')} className="h-full w-full"></iframe>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default PlayerPage;
